import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, CircularProgress, Icon } from '@mui/material';

import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useAccountArtistInfo from '@/hooks/marketing-assistant/useAccountArtistInfo';
import { MarketingAssistantUserInfoModel } from '@/models/MarketingAssistant';
import { CareerHistoryObject } from '@/models/MarketingAssistant';
import MarketingAssistantAPI from '@/network/MarketingAssistantAPI';

import { SocialMediaInputs } from '../utility/form-inputs/SocialMediaInputs';
import Loading from '../utility/Loading';
import ButtonComponent from '../utility/microcomponents/Button';
import Card from '../utility/microcomponents/Card';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import AddCareerEventModal from '../utility/modals/AddCareerEventModal';

const AIGeneratorPage = () => {
  const { accountId } = useAccountContext();
  const { artist } = useArtist();
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatchSnackbar } = useSnackbarContext();

  const [addEventModalOpen, setAddEventModalOpen] = useState<boolean>(false);
  const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);

  const { accountArtistInfo, accountArtistInfoIsLoading, refetchAccountArtistInfo } = useAccountArtistInfo();

  const defaultValues = {
    artistName: '',
    artistDescription: '',
    originStory: '',
    birthLocation: '',
    currentLocation: '',
    careerHistory: null,
    releaseCollaborators: [],
    releaseDate: '',
    releaseDescription: '',
    releaseGenres: [],
    releaseTitle: '',
    releaseType: '',
    socials: {
      facebook: '',
      instagram: '',
      twitter: '',
      youTube: '',
      snapChat: '',
      tikTok: '',
      discord: '',
    },
  };

  const formMethods = useForm<MarketingAssistantUserInfoModel>({
    defaultValues,
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const {
    register,
    watch,
    setValue,
    formState: { isValid },
  } = formMethods;
  const formState = watch();

  const updateUserInfo = useCallback(async () => {
    if (!accountId || !artist?.id) return;
    setIsSavingLoading(true);
    try {
      await MarketingAssistantAPI.updateMarketingAssistantUserInfo({
        accountId: accountId,
        artistId: artist?.id,
        data: formState,
      });
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          type: 'success',
          message: 'Your artist info has been updated',
        },
      });
    } finally {
      refetchAccountArtistInfo();
      setIsSavingLoading(false);
      if (location?.state?.redirect !== undefined) {
        navigate(location.state?.redirect);
      }
    }
  }, [
    accountId,
    artist?.id,
    dispatchSnackbar,
    formState,
    location.state?.redirect,
    navigate,
    refetchAccountArtistInfo,
  ]);

  useEffect(() => {
    if (accountArtistInfo) {
      setValue('artistName', accountArtistInfo.artistName);
      setValue('artistDescription', accountArtistInfo.artistDescription);
      setValue('originStory', accountArtistInfo.originStory || '');
      setValue('birthLocation', accountArtistInfo.birthLocation);
      setValue('currentLocation', accountArtistInfo.currentLocation);
      setValue('socials', accountArtistInfo.socials);
      setValue('careerHistory', accountArtistInfo.careerHistory);
    }
  }, [accountArtistInfo, setValue]);

  if (accountArtistInfoIsLoading)
    return (
      <div className="centered-loading">
        <Loading />
      </div>
    );

  return (
    <>
      <Card className="max-w500 mb80">
        <AddCareerEventModal
          open={addEventModalOpen}
          output={(event) => {
            if (event) {
              setValue('careerHistory', [...(formState.careerHistory || []), event as CareerHistoryObject]);
            }
            setAddEventModalOpen(false);
          }}
        />
        <FormProvider {...formMethods}>
          <div>
            <div>
              <div className="d-flex gap10 w100p flex-wrap">
                <label className="flex-grow">
                  <p className="fw-bold">Birth Location</p>
                  <input placeholder="Where were you born?" {...register('birthLocation')} maxLength={30} />
                </label>
                <label className="flex-grow">
                  <p className="fw-bold">Currently Based</p>
                  <input placeholder="Where do you live?" {...register('currentLocation')} maxLength={30} />
                </label>
              </div>

              <p className="mt20 fw-bold">Origin story</p>
              <p className="small text-faded">Tell us about your journey into music and what inspired you</p>
              <textarea
                className="mt10"
                placeholder="Tell us a bit about yourself"
                {...register('originStory')}
                maxLength={500}
              />

              <p className="mt20 fw-bold">Career history</p>
              <p className="small text-faded">Tell us about notable dates of your life and career</p>
              {formState?.careerHistory &&
                Array.isArray(formState?.careerHistory) &&
                formState?.careerHistory
                  ?.sort(
                    (a: CareerHistoryObject, b: CareerHistoryObject) =>
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                  )
                  .reverse()
                  .map((item, index) => (
                    <Card innerInner key={index} className="d-flex gap10 mt10 p10">
                      <h5 className="mt-auto mb-auto min-w100">{item.date}</h5>
                      <p className="mt-auto mb-auto text-left word-break">{item.description}</p>
                      <ButtonComponent
                        isCustom={true}
                        className="mt-auto mb-auto ml-auto"
                        onClick={() => {
                          const newCareerHistory = formState.careerHistory?.filter((_, i) => i !== index);
                          if (!newCareerHistory) return;
                          setValue('careerHistory', newCareerHistory);
                        }}
                      >
                        <CustomIcons className="material-symbols-outlined text-error" name="delete" />
                      </ButtonComponent>
                    </Card>
                  ))}
              <div className="w100p d-flex">
                <Button
                  className="btn-white mt10 ml-auto mr0"
                  onClick={() => {
                    setAddEventModalOpen(true);
                  }}
                >
                  <Icon>add</Icon>
                  <span className="fw-bold">Add event</span>
                </Button>
              </div>

              <p className="mt20 fw-bold">Your socials</p>
              <p className="small text-faded">Fill in your social handles for all of the platforms you have</p>
              <SocialMediaInputs />
            </div>
          </div>
        </FormProvider>
      </Card>
      <div className="pos-fix b20 r20">
        <Button disabled={isSavingLoading || !isValid} className="btn-white m0 mt20 min-w150" onClick={updateUserInfo}>
          {isSavingLoading ? (
            <CircularProgress size={16} />
          ) : (
            <>
              <CustomIcons name="save" className="material-symbols-outlined pr4" />
              Save changes
            </>
          )}
        </Button>
      </div>
    </>
  );
};

export default AIGeneratorPage;
